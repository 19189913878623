<template>
  <div
    class="d-flex flex-wrap py-4 pa-3"
    style="height: calc(100vh - 300px)"
  >
    <div class="d-flex align-center">
      <back-button @close-event="closeDialog"/>
      <span
        class="text-h2 font-weight-bold"
      >{{ $t('timeSetting') }}</span>
    </div>
    <div
      v-if="selectedWeekdayId"
      class="font-weight-bold text-h4 py-4"
      style="width:100%"
    >
      <div>
        {{ $t('selectedTimeSettingDay') }} {{ daySelected.name }}
      </div>
    </div>
    <div
      style="display: grid;grid-gap: 48px"
      :style="$vuetify.breakpoint.mdAndDown ? 'grid-template-columns: 1fr;' : 'grid-template-columns:0.7fr 1fr;'"
    >
      <div>
        <v-card
          v-for="weekday in timeListLocal"
          :key="weekday.id"
          class="mt-2 pa-2 px-4 d-flex align-baseline"
          elevation="0"
          @click="editWeekday(weekday)"
        >
          <div class="d-flex align-center flex-wrap">
            <div
              :class="selectedWeekdayId===weekday.id?'font-weight-black':''"
              class="text-body-1"
            >
              {{ weekday.name }}
            </div>
            <div
              v-for="time in weekday.timeList"
              :key="time.startTime+time.endTime"
              style="width: 100%;"
            >
              <div
                class="text-body-2 d-flex ml-1 mr-0"
              >
                <div>
                  {{ time.startTime }}
                </div>
                <div
                  v-if="time.startTime&&time.endTime"
                  class="pl-1 pr-1"
                >
                  -
                </div>
                <div>
                  {{ time.endTime }}
                </div>
              </div>
            </div>
          </div>
          <div class="ml-auto mr-0">
            <v-icon size="20">
              mdi-chevron-right
            </v-icon>
          </div>
        </v-card>
      </div>
      <div>
        <v-form
          v-for="(a,index) in editingTimeList"
          :key="a.id"
          class="d-flex align-center mb-1 mt-2"
        >
          <v-select
            v-model="a.startTime"
            :item-disabled="shouldDisableItems"
            :items="selectTimeList"
            :label="$t('startOpenTime')"
            hide-details
            item-text="start"
            item-value="start"
            outlined
            style="width: 50%; max-width: 50%;"
            @focus="currentIndex=index*2"
          />
          <v-divider
            class="ml-2 mr-2"
            vertical
          />
          <v-select
            v-model="a.endTime"
            :item-disabled="shouldDisableItems"
            :items="selectTimeList"
            :label="$t('endOpenTime')"
            hide-details
            item-text="start"
            item-value="start"
            outlined
            style="width: 50%; max-width: 50%;"
            @focus="currentIndex=index*2+1"
          />
          <div style="width: 60px">
            <v-btn
              :disabled="editingTimeList.length===0||(!a.startTime&&!a.endTime)"
              class="ml-1"
              icon
              @click="editingTimeList.splice(index, 1)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-form>
      </div>
    </div>
    <template v-if="$vuetify.breakpoint.lgAndUp">
      <v-btn
        :disabled="shouldNotSubmit"
        block
        class="primary mt-4 mr-4"
        elevation="0"
        large
        @click="save"
      >
        {{ $t('save') }}
      </v-btn>
    </template>
    <template v-else>
      <fab-button
        fab-fixed
        color="white"
        fab-bottom
        fab-right
        icon="mdi-content-save"
        background="green"
        @click-event="save"
      />
    </template>
  </div>
</template>

<script>

  import WeekDays from '@/model/common/WeekDays'
  import { groupBy, sortBy } from 'lodash-es'
  import { changeToMinute } from '@/views/AadenPages/settings/reserveSetting/fragment/NewAddForm'
  import IKUtils from 'innerken-js-utils'
  import { timeArrays } from '@/model/common/OpenTimeList'
  import FabButton from '@/views/AadenPages/components/FabButton/FabButton'
  import BackButton from '@/composable/InK/BackButton'

  let counter = 0
  export default {
    name: 'TimePicker',
    components: {
      FabButton,
      BackButton,
    },
    props: {
      timeList: {},
    },
    data: function () {
      return {
        defaultEatTime: [
          v => v <= 4 || this.$t('maxDefaultTime'),
          v => v > 0 || this.$t('minDefaultTime'),
        ],
        weekdayList: [],
        selectTimeList: [],
        editingTimeList: [],
        selectedWeekdayId: null,
        currentIndex: -50,
        timeListLocal: [{
          dayOfWeek: 1,
          timeList: [{
            dayOfWeek: 1,
            start: 'xxx',
            end: 'xxx',
          }, {
            start: 'xxx',
            end: 'xxx',
          }],
        }],
      }
    },
    computed: {
      shouldNotSubmit () {
        return this.editingTimeList.some(it => (it.startTime !== '' && it.endTime === '') || (it.startTime === '' && it.endTime !== ''))
      },
      realStartedTimeList () {
        return this.timeListLocal.find(it => it.id === this.selectedWeekdayId)?.timeList ?? []
      },
      lowerBound () {
        return this.openTimeMinuteListOrdered?.[this.currentIndex - 1] ?? -1
      },
      upperBound () {
        return this.openTimeMinuteListOrdered?.[this.currentIndex + 1] ?? 9999
      },
      notEmptyOpenTimeList () {
        return this.editingTimeList.filter(it => it.startTime || it.endTime)
      },
      openTimeMinuteListOrdered () {
        return sortBy(this.notEmptyOpenTimeList.map(it => [it.startTime, it.endTime]).flat().map(changeToMinute))
      },
      daySelected () {
        return this.weekdayList.find(it => it.id === this.selectedWeekdayId)
      },
    },
    watch: {
      realStartedTimeList: {
        deep: true,
        handler (val) {
          this.editingTimeList = val
        },
      },
      editingTimeList: {
        deep: true,
        handler (val) {
          const emptyNode = val.filter(it => !it.startTime && !it.endTime).length
          if (emptyNode === 0) {
            this.editingTimeList.push(this.createDefaultTimeSlotItem())
          }
        },
      },
      timeList: {
        immediate: true,
        handler (val) {
          const result = groupBy(val, 'dayOfWeek')
          this.timeListLocal = this.weekdayList.map(it => {
            it.timeList = result[it.id] ?? []
            it.dayOfWeek = it.id
            return it
          })
        },
      },
    },
    async mounted () {
      this.weekdayList = WeekDays.getList()
      this.selectedWeekdayId = this.weekdayList[0].id
      this.selectTimeList = timeArrays
    },
    methods: {
      async closeDialog () {
        this.$emit('close-dialog')
      },
      shouldDisableItems (item) {
        const itemInMinute = changeToMinute(item.start)
        return !(this.lowerBound < itemInMinute && itemInMinute < this.upperBound)
      },
      editWeekday (item) {
        this.selectedWeekdayId = item.id
      },
      createDefaultTimeSlotItem () {
        const obj = IKUtils.deepCopy({
          startTime: '',
          endTime: '',
          dayOfWeek: this.selectedWeekdayId,
        })
        obj.key = counter++
        return obj
      },
      async save () {
        const arr = this.timeListLocal.map(it => it.timeList).flat().filter(it => it.startTime && it.endTime)
        console.log(arr)
        this.$emit('changed', arr)
      },
    },
  }
</script>
