<template>
  <div>
    <v-card
      :class="!$vuetify.breakpoint.lgAndUp ? 'pa-2 px-4' : 'pa-4 px-6'"
      elevation="0"
    >
      <div class="font-weight-bold text-h4">
        {{ $t('openTimeSetting') }}: {{ weekdayName }}
      </div>
      <div
        v-if="reservationReady"
      >
        <div class="mt-8">
          {{ $t('setDefaultTime') }}
        </div>
        <div
          class="text-caption"
        >
          {{ $t('reservationDefaultTimeRule') }}
        </div>
        <v-form
          ref="defaultEatTime"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="reserveTime"
            :placeholder="$t('estimatedMealTime')"
            :rules="defaultEatTime"
            :suffix="$t('hour')"
            dense
            outlined
            step="0.25"
            type="number"
          />
        </v-form>
      </div>
      <div class="mt-6">
        {{ $t('openTimeSetting') }}
      </div>
      <div
        v-for="(a,index) in openTimeList"
        :key="a.key"
        class="d-flex align-center mb-1 mt-2"
      >
        <v-select
          v-model="a.startTime"
          :item-disabled="shouldDisableItems"
          :items="selectTimeList"
          :label="$t('openTime')"
          hide-details
          item-text="start"
          item-value="start"
          outlined
          @focus="currentIndex=index*2"
        />
        <v-divider
          class="ml-2 mr-2"
          vertical
        />
        <v-select
          v-model="a.endTime"
          :item-disabled="shouldDisableItems"
          :items="selectTimeList"
          :label="$t('endOpenTime')"
          hide-details
          item-text="start"
          item-value="start"
          outlined
          @focus="currentIndex=index*2+1"
        />
        <div style="width: 60px">
          <v-btn
            :disabled="openTimeList.length===0||(!a.startTime&&!a.endTime)"
            class="ml-1"
            icon
            @click="openTimeList.splice(index, 1)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
      <template v-if="$vuetify.breakpoint.lgAndUp">
        <div class="d-flex">
          <v-btn
            :disabled="!valid"
            block
            class="primary mt-4 mr-4"
            elevation="0"
            large
            @click="save"
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </template>
      <template v-else>
        <div class="text-right mt-4">
          <fab-button
            background="green"
            fab-relative
            :fab-disabled="!valid"
            @click-event="save()"
          />
        </div>
      </template>
    </v-card>
  </div>
</template>

<script>
  import IKUtils from 'innerken-js-utils'
  import OpeningTime from '@/model/takeaway/OpeningTime'
  import { timeArrays } from '@/model/common/OpenTimeList'
  import { editReserveDefaultUsefulTime, reservationReady } from '@/common/Utlis/api'
  import { sortBy } from 'lodash-es'
  import FabButton from '@/views/AadenPages/components/FabButton/FabButton'

  export function changeToMinute (timeStr) {
    const [hour, minute] = timeStr.split(':').map(it => parseInt(it))
    return hour * 60 + minute
  }

  let counter = 0
  const defaultDate = {
    startTime: '',
    endTime: '',
  }

  export default {
    name: 'NewAddForm',
    components: {
      FabButton,
    },
    props: {
      searchOpenTime: {},
      showList: {},
      dayOfWeek: {
        type: Number,
      },
      defaultReserveTime: {},
    },
    data: function () {
      return {
        defaultEatTime: [
          v => v <= 4 || this.$t('maxDefaultTime'),
          v => v > 0 || this.$t('minDefaultTime'),
        ],
        currentIndex: -50,
        selectTimeList: null,
        timeArrays,
        isLoading: false,
        trueDefaultTime: null,
        weekdayName: '',
        reserveTime: null,
        valid: true,
        openTimeList: [],
        reservationReady: false,
      }
    },
    computed: {
      lowerBound () {
        return this.openTimeMinuteListOrdered?.[this.currentIndex - 1] ?? -1
      },
      upperBound () {
        return this.openTimeMinuteListOrdered?.[this.currentIndex + 1] ?? 9999
      },
      notEmptyOpenTimeList () {
        return this.openTimeList.filter(it => it.startTime || it.endTime)
      },
      openTimeMinuteListOrdered () {
        return sortBy(this.notEmptyOpenTimeList.map(it => [it.startTime, it.endTime]).flat().map(changeToMinute))
      },
    },
    watch: {
      dayOfWeek (val, old) {
        if (old) {
          this.reload()
        }
      },
      openTimeList: {
        deep: true,
        handler (val) {
          const emptyNode = val.filter(it => !it.startTime && !it.endTime).length
          if (emptyNode === 0) {
            this.openTimeList.push(this.createDefaultTimeSlotItem())
          }
        },
      },
    },
    async mounted () {
      this.reload()
      await this.validation()
    },
    methods: {
      shouldDisableItems (item) {
        const itemInMinute = changeToMinute(item.start)
        return !(this.lowerBound < itemInMinute && itemInMinute < this.upperBound)
      },
      reload () {
        this.openTimeList = this.showList.filter(it => it.weekday[0] === this.dayOfWeek)
        const time = this.defaultReserveTime.find(it => it.id === this.dayOfWeek)
        this.reserveTime = time?.useTime
        this.weekdayName = time?.name
        this.selectTimeList = timeArrays
      },
      async save () {
        if (this.$refs.defaultEatTime && this.$refs.defaultEatTime.validate()) {
          await editReserveDefaultUsefulTime({
            dayOfWeek: this.dayOfWeek,
            gapCount: this.reserveTime * 4,
          })
        }
        for (const items of this.showList.filter(it => it.weekday[0] === this.dayOfWeek)) {
          await OpeningTime.remove(items.id)
        }
        for (const item of this.openTimeList) {
          if (item.startTime !== '' && item.endTime !== '') {
            await OpeningTime.add({
              ...item,
              weekday: this.dayOfWeek,
            })
          }
        }
        IKUtils.toast()
        this.$emit('reload')
      },
      createDefaultTimeSlotItem () {
        const obj = IKUtils.deepCopy(defaultDate)
        obj.key = counter++
        return obj
      },
      async validation () {
        this.reservationReady = await reservationReady()
      },
    },
  }
</script>

<style scoped>

</style>
