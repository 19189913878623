import i18n from '../../i18n'

export default {
  list: [
    {
      id: 1,
      name: i18n.t('Monday'),
    }, {
      id: 2,
      name: i18n.t('Tuesday'),
    }, {
      id: 3,
      name: i18n.t('Wednesday'),
    }, {
      id: 4,
      name: i18n.t('Thursday'),
    }, {
      id: 5,
      name: i18n.t('Friday'),
    }, {
      id: 6,
      name: i18n.t('Saturday'),
    }, {
      id: 7,
      name: i18n.t('Sunday'),
    },
  ],
  getList: function () {
    return this.list
  },
}
